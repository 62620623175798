import React, { lazy, Suspense } from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import 'bootstrap/dist/css/bootstrap.css'
import './assets/layout.css'

const SelectCompany = lazy(() => import('./SelectCompany'))
const LoanTerms = lazy(() => import('./LoanTerms'))

function App() {
  return (
    <Router>
      <Suspense fallback={<div>Loading...</div>}>
        <div className="layout">
          {/*
          <nav className="menu">
            <ul>
              <li>
                <Link to="/">Transactions</Link>
              </li>
              <li>
                <Link to="/loan">Loan</Link>
              </li>
            </ul>
          </nav>
          */}
          <div className="content">
            <Switch>
              <Route exact path="/" component={SelectCompany} />
              <Route exact path="/loan" component={LoanTerms} />
            </Switch>
          </div>
        </div>
      </Suspense>
    </Router>
  )
}

export default App
